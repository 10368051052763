import * as types from "../types/index";
import { APP_KEY, REACT_APP_X_AUTHORIZATION } from "../../config/ApiConstants";
import CryptoJs from "crypto-js";

export function validate(password) {
  let pwd = CryptoJs.MD5(password).toString();

  if (pwd === APP_KEY) {
    return {
      type: types.IS_VALID,
    };
  } else {
    return {
      type: types.IS_INVALID,
    };
  }
}

export const reset_archives_action = () => {
  return {
    type: types.RESET_ARCHIVES,
    payload: null,
  };
};
